//@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap");

* {
  margin: 0;
  padding: 0;
  //box-sizing: border-box;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier,
    monospace;
}

li {
  list-style: none;
}

a {
  color: white;
  &:visited {
    text-decoration: none;
    //color: white;
  }
  &:focus {
    text-decoration: none;
    //color: white;
  }
}

/*
.nav-item {
  &:target {
    text-decoration: none;
  }
  &:hover {
    font-weight: bold;
    //text-decoration: none;
  }
}
*/
/*
.header {
  border-bottom: 1px solid #e2e8f0;
  background-color: #101010;
}
*/
.navbar {
  //height: 10vh;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 5vh;
  //display: flex;
  //justify-content: center;
  //align-items: center;

  //padding: 2rem 1.5rem;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  //size: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  //margin-left: 2rem;
}
/*
.nav-link {
  font-size: 1.6rem;
  font-weight: 400;
  color: #475569;
}

.nav-link:hover {
  color: #482ff7;
}

.nav-logo {
  font-size: 2.1rem;
  font-weight: 500;
  color: #ffffff;
}
*/
